import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import Navbar from '@components/elements/Navbar'
import Footer from '@components/elements/Footer'
import Modal from '@components/elements/Modal/Modal'
import components from '../content/common'
import { AnimatePresence } from 'framer-motion'
import RadlertourHeader from '@components/modules/RadlertourHeader'
import radlertourPosterPreview from '@images/events/Radler-Tour-Plakat-960Pixel.jpg'
import VideoPlayer from '@components/modules/VideoPlayer'
import YtPoster from '@images/events/Radler-Tour-Video.jpg'

const Container = styled.div`
  filter: ${(props) => (props.modal ? 'blur(20px)' : 'none')};
  image-rendering: ${(props) =>
    props.isChrome ? '-webkit-optimize-contrast !important;' : 'undefined'};
`

const ContentWrap = tw.div`max-w-master mx-auto pb-10 pt-5 sm:py-10 md:py-24 px-5`
const Heading = tw.h1`font-lobster text-green text-[22px] md:text-4xl tracking-[0.02em] md:leading-[50px] pb-5 md:pb-8`
const Text = tw.p`text-15px leading-[18px] text-gray font-roboto sm:text-18px lg:tracking-wide`
const Box = tw.div`text-15px text-gray border-2 border-green font-roboto sm:text-18px lg:tracking-wide p-2 mb-12`

const radlertourPosterDownload = '/download/Radler-Tour-Plakat.pdf'
const radlertourTnb = '/download/Teilnahmebedingungen_ERDINGER-Brauhaus_RadlerTour.pdf';

const EventsPage = () => {
  const { modal, modalHandle, sticky } = useStateContext()

  useEffect(() => {
    if (modal) {
      document.documentElement.style.overflowY = 'hidden'
    }
    return () => {
      document.documentElement.style.overflowY = 'auto'
    }
  }, [modal])
  const isChrome = typeof window !== 'undefined' && !!window.chrome
  return (
    <>
      <Container modal={modal} isChrome={isChrome}>
        <Navbar content={components.navbar} />
        <RadlertourHeader />
        <ContentWrap>
          <Box>
            Sensationeller Auftakt der ERDINGER Brauhaus RadlerTour am Vatertag!
            <br /><br />
            Trotz unsicherer Wetterlage ließen sich zahlreiche Teilnehmer die legendäre Familien-Fahrradtour nicht 
            entgehen und freuten sich über eine Schnitzeljagd mit jeder Menge Spaß und Bewegung.<br />
            Es wurde gemeinsam geradelt, gerätselt und verschiedene Stationen mit Bravour gemeistert. Sie jagten 
            Hinweisen hinterher und bewiesen ihre Geschicklichkeit – der Wettkampfgeist wuchs von Kilometer zu 
            Kilometer, bis die Teilnehmer schließlich das Ziel am Sepp-Brenninger-Stadion erreichten.
            <br /><br />
            Leider mussten wir auf Grund der Witterungsbedingungen die geplante Siegerehrung kurzfristig vorziehen.
            <br />
            Aber keine Sorge, unsere 90 Preise werden natürlich trotzdem den rechtmäßigen Gewinnern überreicht. In 
            den nächsten Tagen werden wir alle Gewinner schriftlich per Email kontaktieren. Drückt die Daumen! 
            Vielleicht <nobr>seid´s</nobr> ja auch dabei?
          </Box>
          <Heading>
            Premiere für die ERDINGER Brauhaus RadlerTour 
          </Heading>
          <Text>
            An Christi Himmelfahrt lädt ERDINGER zum Radfahren durch den Landkreis ein
            <br /><br />
            In Erding wird wieder gemeinsam geradelt. Mit der RadlerTour lässt ERDINGER Brauhaus eine Tradition, die bereits 1996 begann, neu aufleben: die legendäre Familien-Fahrradtour am Vatertag.
            <br /><br />
            Geplant als Schnitzeljagd, warten am 18. Mai (Feiertag) neben jeder Menge Bewegung und Spaß auch tolle Preise auf die Teilnehmer. Start ist zwischen 9 und 11 Uhr <a href="https://www.komoot.de/tour/1036940172" className="text-green" target="_blank">am Sepp-Brenninger Stadion in Altenerding</a>. Ab 12 Uhr öffnet dann ebenso für alle Nicht-Radfahrer das Veranstaltungsgelände. Grillschmankerl, Getränke und Musik von der Erdinger Stadtkapelle laden zum Verweilen ein.
            <br /><br />
            <a href="https://www.komoot.de/tour/1036940172" className="text-green" target="_blank">ERDINGER Brauhaus RadlerTour Streckeninfos &gt;&gt;</a>
          </Text>
          <br />
          <a
            href={radlertourPosterDownload}
            download
            className="inline-block"
          >
            <img
              className="inline-block object-contain sm:h-auto"
              src={radlertourPosterPreview}
              alt="Herunterladen"
            />
          </a>
          <br /><br />
          <Text>
            Geradelt wird auf circa 30 km entlang der Sempt bis nach Wörth, weiter nach Neuching, am Isarkanal bis nach Moosinning und dann auf Nebenstraßen wieder zurück zum Stadion. Dabei geht es nicht um Schnelligkeit, denn unterwegs gilt es verschiedene Stationen zu meistern und Fragen zu beantworten. Wer seinen Blick durch Natur und Landschaft schweifen lässt und auf historische Plätze achtet, der ist beim Punktesammeln klar im Vorteil. 
            <br /><br />
            Für alle Teilnehmer besteht eine Helmpflicht. Starten kann jeder kostenlos und ohne Voranmeldung. Lediglich die Abholung des Startbogens zwischen 9 und 11 Uhr am Veranstaltungstag selbst ist für die Teilnahme erforderlich. Dieser muss dann bis spätestens 17 Uhr zur Punkteauswertung wieder am Sepp-Brenninger Stadion abgegeben werden. Anschließend werden gegen 18 Uhr die Sieger gekürt. Für die Gewinner werden Preise wie eine Ballonfahrt, ein Jahr Freibier, GOP-Tickets oder Eintrittskarten für die Therme Erding ausgelobt. Nach der Siegerehrung ist Zeit für ein zünftiges Fest. Gefeiert wird mit DJ und einem Gastauftritt von Nicki, die mit Hits wie „Wenn i mit dir tanz“ für Stimmung sorgt.
            <br /><br />
            <a href={radlertourTnb} className="text-green" target="_blank">Zu den Teilnahmebedingungen &gt;&gt;</a>
          </Text>
          <br />
          <VideoPlayer
            url='https://www.youtube-nocookie.com/embed/NG7mlTWDWNw?rel=0'
            poster={YtPoster}
            text='ERDINGER Brauhaus - Radler-Tour 2023'
          />
        </ContentWrap>
      </Container>
      <AnimatePresence exitBeforeEnter>
        {modal && <Modal key="modal" handleClose={() => modalHandle(false)} />}
      </AnimatePresence>
      <Footer stickyVisible={sticky} />
    </>
  )
}

export default EventsPage
