import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import { useStateContext } from '@context/stateContext'
import { motion } from 'framer-motion'

const VideoWrap = tw.div`relative max-w-master mx-auto w-full px-5 lg:px-0`
const IframeWrap = styled.div`
  ${tw`relative overflow-hidden`}
  aspect-ratio: 16 / 9;

  .play {
    ${tw`ml-2 transition duration-300 w-[25px] h-[25px] border-t-[15px] border-r-0 border-b-[15px] border-l-[25px] sm:w-[45px] sm:h-[45px] sm:border-t-[30px] sm:border-r-0 sm:border-b-[30px] sm:border-l-[45px]`}
    border-color: transparent transparent transparent #006e42;
  }
  .wrap-play {
    ${tw`absolute flex items-center justify-center transition duration-300 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full shadow-2xl cursor-pointer w-18 h-18 sm:w-28 sm:h-28 top-1/2 left-1/2`}

    &:hover {
      ${tw`bg-radler`}

      .play {
        border-color: transparent transparent transparent #fff;
      }
    }
  }
`
const CookieNotification = tw(
  motion.div
)`absolute bottom-0 left-0 right-0 bg-helles w-full px-5 max-w-master mx-auto py-5 text-white flex flex-col xs:flex-row justify-between items-center text-xs`
const CookieText = tw.div`text-white`
const ConsentButton = tw.div`bg-radler text-white text-xs md:text-15px mt-3 xs:mt-0 px-2 md:px-4 py-1 md:py-2 rounded cursor-pointer`

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const VideoPlayer = ({ poster, title, url }) => {
  const { ytConsent, setYtConsent } = useStateContext()
  const [showNotification, setShowNotification] = React.useState(false)
  React.useEffect(() => {
    const cookieValue = getCookie('youtube-consent')
    setYtConsent(cookieValue)
  }, [ytConsent])

  return (
    <VideoWrap>
      <IframeWrap className="aspect-w-16 aspect-h-9">
        {(ytConsent === 'false' || !ytConsent) && (
          <>
            <img
              className="w-full h-full"
              src={poster}
              alt={title}
            />
            <div
              className="wrap-play"
              onClick={() => setShowNotification(true)}
            >
              <div className="play"></div>
            </div>
            {showNotification && (ytConsent === 'false' || ytConsent === '') && (
              <CookieNotification
                initial={{ y: '100%' }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, type: 'tween' }}
              >
                <CookieText>
                  Ich möchte YouTube-Inhalte aktivieren und stimme zu, dass
                  Daten von YouTube geladen werden. Zu den{' '}
                  <Link className="inline-block underline" to="/datenschutz/">
                    Datenschutzhinweisen
                  </Link>
                  .
                </CookieText>
                <ConsentButton
                  onClick={() => {
                    setYtConsent('true')
                    setCookie('youtube-consent', true, 356)
                  }}
                >
                  Akzeptieren
                </ConsentButton>
              </CookieNotification>
            )}
          </>
        )}
        {ytConsent === 'true' && (
          <iframe
            className="w-full h-full mb-2"
            title={title}
            src={url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        )}
      </IframeWrap>
    </VideoWrap>
  )
}
export default VideoPlayer
